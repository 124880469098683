import { Dialog, DialogTitle, DialogContent, DialogActions, Collapse, Card, Button, TextField, Box, Divider, Grid, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import MapIcon from '@mui/icons-material/Map';
import { AccountBalanceWallet, BatchPrediction, Payment, PinDrop, RequestQuote, } from "@mui/icons-material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { MainButton } from '@vkruglikov/react-telegram-web-app';
import axios from 'axios';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { apiUrl } from "../config";
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import SellIcon from '@mui/icons-material/Sell';


export const PlaceOrder = ({ storeCode, WebApp, storePaymentInfo, cartItemList }) => {

    const [deliverMode, setDeliverMode] = React.useState('Delivery');
    const [location, setLocation] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('Cash');
    const [isBankPayDialogOpened, setIsBankPayDialogOpened] = useState(false);

    const [isValidForm, setIsValidForm] = useState(true);
    const [isOrdering, setIsOrdering] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [remark, setRemark] = useState('');
    const [locationName, setLocationName] = useState('');
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
    const [expectedDeliveryTime, setExpectedDeliveryTime] = useState('');

    // const [note, setNote] = useState('');

    const [impactOccurred, notificationOccurred, selectionChanged] =
        useHapticFeedback();
    const [initDataUnsafe, initData] = useInitData();

    const selectDeliverMode = (event, newAlignment) => {
        setDeliverMode(newAlignment);
    };

    const handleLocationClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }
    }
    const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({ latitude, longitude });
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }

    const error = () => {
        console.log("Unable to retrieve your location");
    }

    const PlaceOrderClick = () => {
        // console.log(location);
        if (!name || !phoneNumber ) {
            setIsValidForm(false);
            return;
        }

        // if((!location && deliverMode === "Delivery"))
        // {
        //     setIsValidForm(false);
        //     return;
        // }

        if (isOrdering)
            return;

        setIsOrdering(true);
        var orderInfo = {
            subTotalAmount: cartItemList.reduce(function (prev, current) {
                return prev + (current.qty * current.salePrice)
            }, 0).toFixed(2),
            taxAmount: 0,
            discountAmount: 0,
            total: cartItemList.reduce(function (prev, current) {
                return prev + (current.qty * current.salePrice)
            }, 0).toFixed(2),
            status: "Order",
            saleItems: cartItemList?.map(i => {
                return {
                    itemCode: i.itemCode,
                    itemName: i.itemName,
                    quantity: i.qty,
                    salePrice: i.salePrice
                }
            }),
            // note: note,
            paymentMethod: paymentMethod,
            deliverMode: deliverMode,
            customer: {
                name: name,
                phoneNumber: phoneNumber
            },
            deliveryLocation: {
                latitude: location?.latitude?.toString(),
                longitude: location?.longitude?.toString()
            },
            deliveryDetail: {
                locationName: locationName,
                expectedDeliveryDate: expectedDeliveryDate,
                expectedDeliveryTime: expectedDeliveryTime,
                remark: remark
            },
        };
        // console.log(orderInfo);

        axios.post(`${apiUrl}Public/store/${storeCode}/order`, orderInfo).then(res => {
            if (res.data.isSuccess === true) {

            }
            notificationOccurred('success'); //Vibrate
            setIsOrdering(false);
            WebApp.close();
        }).catch(error => {
            notificationOccurred('error'); //Vibrate
        }).finally(() => {
            setIsOrdering(false);
        })



        // WebApp.close();
    }

    React.useEffect (()=>{
        handleLocationClick();
    },[]);

    return <>

        {window.location.href.includes('localhost') && (<Button onClick={PlaceOrderClick}>Place Order</Button>)}

        <MainButton progress={isOrdering} text="Place Order" onClick={PlaceOrderClick} />

        <Box>

            {/* <TextField value={JSON.stringify(initDataUnsafe)}/>
            <TextField value={JSON.stringify(WebApp)}/> */}

            <Paper elevation={1}>
                <Box p={2}>
                    <Box >
                        <ToggleButtonGroup sx={{ width: '100%' }}
                            color="primary"
                            value={deliverMode}
                            exclusive
                            onChange={selectDeliverMode}
                            aria-label="Platform"
                        >
                            <Grid container>
                                <Grid xs={12}>
                                    <ToggleButton fullWidth value="Delivery" sx={{ textTransform: 'none' }}>Delivery</ToggleButton>
                                </Grid>
                                {/* <Grid xs={6}>
                                    <ToggleButton fullWidth value="PickUp" sx={{ textTransform: 'none' }}>Pick up</ToggleButton>
                                </Grid> */}
                            </Grid>
                        </ToggleButtonGroup>
                    </Box>
                    <Divider />
                    {/* {deliverMode === "Delivery" && (
                        <Box my={2} >

                            <Stack direction="row" spacing={1}>
                                <SellIcon />   <Typography sx={{ fontWeight: '500' }} variant="body1" gutterBottom> Order Information</Typography>
                            </Stack>
                        </Box>
                    )} */}
                    {deliverMode === "PickUp" && (
                        <Box my={2} >
                            <Stack direction="row" spacing={1}>
                                <img width='24' src={require('./../assets/images/pickup.png')} />
                                <Typography sx={{ fontWeight: '500' }} variant="body1" gutterBottom> Pick up</Typography>
                            </Stack>

                        </Box>
                    )}
                    <Box my={2} >

                        <Stack direction="row" spacing={1}>
                            <SellIcon />   <Typography sx={{ fontWeight: '500' }} variant="body1" gutterBottom> Order Information</Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Box mt={1}>
                            <Grid container>
                                <Grid xs="12" item>
                                    <Typography sx={{ fontWeight: '400' }} variant="body1"> Contact Name
                                        <Box component='span' sx={{ color: 'red', fontWeight: '500' }}> *</Box>
                                    </Typography>
                                </Grid>
                                <Grid xs="12" item>
                                    <TextField
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        error={!isValidForm && !name}
                                        helperText="Contact name is required!"
                                        placeholder="Contact Name" variant="outlined" fullWidth size='small' />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={1}>
                            <Grid container>
                                <Grid xs="12" item>
                                    <Typography sx={{ fontWeight: '400' }} variant="body1"> Phone Number
                                        <Box component='span' sx={{ color: 'red', fontWeight: '500' }}> *</Box>
                                    </Typography>
                                </Grid>
                                <Grid xs="12" item>
                                    <TextField
                                        value={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        error={!isValidForm && !phoneNumber}
                                        helperText="Phone number is required!"
                                        placeholder="Phone Number" variant="outlined" fullWidth size='small' />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={1}>
                            <Grid container>
                                <Grid xs="12" item>
                                    <Typography sx={{ fontWeight: '400' }} variant="body1"> Remark
                                    </Typography>
                                </Grid>
                                <Grid xs="12" item>
                                    <TextField
                                        value={remark}
                                        onChange={e => setRemark(e.target.value)}
                                        multiline rows={5} variant="outlined" fullWidth size='small' />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={1}>
                            <Divider />
                        </Box>
                        {deliverMode === "Delivery" && (
                            <Box mt={1}  >

                                <Stack direction="row" spacing={1}>
                                    <MapIcon />   <Typography sx={{ fontWeight: '500' }} variant="body1" gutterBottom> Location </Typography>
                                </Stack>
                            </Box>
                        )}
                        {!location &&
                            <>
                                <Button sx={{ textTransform: 'none', mt: 1 }} size='small' onClick={handleLocationClick} variant="contained" endIcon={<PinDrop />}>
                                    Use current location
                                </Button>
                                {!isValidForm && !location && (
                                    <Box sx={{ color: 'red' }}>
                                        Click for location
                                    </Box>
                                )}
                            </>
                        }
                        {deliverMode === "Delivery" && (
                            <>
                                <Box mt={1}>
                                    <Grid container>
                                        <Grid xs="12" item>
                                            <Typography sx={{ fontWeight: '400' }} variant="body1"> Location Name
                                            </Typography>
                                        </Grid>
                                        <Grid xs="12" item>
                                            <TextField
                                                value={locationName}
                                                onChange={e => setLocationName(e.target.value)}
                                                //  helperText="Phone number is required!"
                                                //  placeholder="Phone Number" 
                                                variant="outlined" fullWidth size='small' />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={1}>
                                    <Grid container>
                                        <Grid xs="12" item>
                                            <Typography sx={{ fontWeight: '400' }} variant="body1"> Expected Delivery Date
                                            </Typography>
                                        </Grid>
                                        <Grid xs="12" item>
                                            <TextField
                                                value={expectedDeliveryDate}
                                                onChange={e => setExpectedDeliveryDate(e.target.value)}
                                                //  helperText="Phone number is required!"
                                                //  placeholder="Phone Number" 
                                                variant="outlined" fullWidth size='small' />

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={1}>
                                    <Grid container>
                                        <Grid xs="12" item>
                                            <Typography sx={{ fontWeight: '400' }} variant="body1"> Expected Delivery Time
                                            </Typography>
                                        </Grid>
                                        <Grid xs="12" item>
                                            <TextField
                                                value={expectedDeliveryTime}
                                                onChange={e => setExpectedDeliveryTime(e.target.value)}
                                                variant="outlined" fullWidth size='small' />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )}

                    </Box>
                </Box>
            </Paper>
            <Box mt={1}></Box>
            <Paper elevation={1}>
                <Box p={2}>
                    <Box mb={2}>
                        <Stack direction="row" spacing={1}>
                            <Payment />
                            <Typography sx={{ fontWeight: '500' }} variant="body1" gutterBottom> Payment Method</Typography>
                        </Stack>
                    </Box>

                    <Box>
                        <Paper onClick={() => setPaymentMethod('CashOnDelivery')} variant="outlined" elevation={paymentMethod == "CashOnDelivery" ? 2 : 1} sx={{
                            p: 2,

                            border: paymentMethod == "CashOnDelivery" ? '1px solid green' : '1px dotted  grey'
                        }} fullWidth>


                            <Grid alignItems="center" container>
                                <Grid item xs={10}>
                                    <Stack direction="row" spacing={1}>
                                        <RequestQuote />
                                        <Typography variant="body1" gutterBottom> Cash on Delivery</Typography>

                                    </Stack>
                                </Grid>

                                {paymentMethod == "CashOnDelivery" && <DoneIcon size="small" color="success" />}
                            </Grid>
                        </Paper>
                    </Box>

                    <Box mt={1}>
                        <Paper onClick={() => {
                            setPaymentMethod('Bank');
                            setIsBankPayDialogOpened(true);
                        }}
                            variant="outlined" elevation={paymentMethod == "Bank" ? 2 : 1} sx={{
                                p: 2,

                                border: paymentMethod == "Bank" ? '1px solid green' : '1px dotted grey'
                            }} fullWidth>
                            <Grid alignItems="center" container>
                                <Grid item xs={10}>
                                    <Stack direction="row" spacing={1}  >
                                        <AccountBalanceWallet />
                                        <Typography sx={{ fontWeight: paymentMethod == "Bank" ? '500' : '400' }} variant="body1" gutterBottom> Bank &nbsp;&nbsp;
                                            {/* {paymentMethod == "Bank" && (
                                                <Box sx={{ color: 'blue' }} onClick={() => setIsBankPayDialogOpened(true)} component='span'>
                                                    (Pay)
                                                </Box>
                                            )} */}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {/* <Grid item xs={2}>
                                    <Box sx={{ textAlign: 'right', p: 0 }}>
                                        {paymentMethod == "Bank" && <DoneIcon size="small" color="success" />}
                                    </Box>
                                </Grid> */}

                                {paymentMethod == "Bank" && <DoneIcon size="small" color="success" />}
                            </Grid>
                        </Paper>
                    </Box>

                    {/* <Collapse in={paymentMethod == 'Bank'}>

                    </Collapse> */}

                </Box>
            </Paper >

            <Box mt={1}></Box>
            <Paper elevation={1}>
                <Box p={2}>
                    <Box mb={2}>
                        <Stack direction="row" spacing={1}>
                            <ReceiptIcon />
                            <Typography sx={{ fontWeight: '500' }} variant="body1" gutterBottom> Order Summary</Typography>
                        </Stack>
                    </Box>

                    <Box>
                        {cartItemList?.map(item =>

                            <Stack direction="row" spacing={1} justifyContent='space-between'>
                                <Box>
                                    <Typography variant="body2">
                                        {item.qty}x  &nbsp; {item.itemName}
                                    </Typography>
                                </Box>
                                <Box>
                                    $ {(item.qty * item.salePrice).toFixed(2)}
                                </Box>
                            </Stack>
                        )}
                    </Box>
                    <Box my={1}>
                        <Divider />
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} justifyContent='space-between'>
                            <Typography variant="body2">Sub total:</Typography>
                            <Typography variant="body2">
                                $ {cartItemList.reduce(function (prev, current) {
                                    return prev + (current.qty * current.salePrice)
                                }, 0).toFixed(2)}
                            </Typography>

                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} justifyContent='space-between'>
                            <Typography variant="body2">Discount :</Typography>
                            <Typography variant="body2">{0.00}</Typography>

                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} justifyContent='space-between'>
                            <Typography variant="body2">VAT :</Typography>
                            <Typography variant="body2">{0.00}</Typography>

                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} justifyContent='space-between'>
                            <Typography variant="body2" sx={{ fontWeight: '600' }}>Grand Total :</Typography>
                            <Typography variant="body2">
                                $ {cartItemList.reduce(function (prev, current) {
                                    return prev + (current.qty * current.salePrice)
                                }, 0).toFixed(2)}
                            </Typography>
                        </Stack>
                    </Box>

                </Box>
            </Paper >


        </Box >

        <Dialog
            open={isBankPayDialogOpened}
            onClose={() => setIsBankPayDialogOpened(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {/* KHQR Code */}
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box> KHQR Code </Box>
                    <Box onClick={() => setIsBankPayDialogOpened(false)} sx={{ color: 'red' }}> <CloseIcon /></Box>
                </Stack>
            </DialogTitle>
            {storePaymentInfo?.khqR_ABA && (
                <DialogContent sx={{ p: 0 }}>
                    <img style={{ maxWidth: "100%" }} src={'data:image/jpeg;base64,' + storePaymentInfo?.khqR_ABA} alt="khqr" />
                </DialogContent>
            )}

            {storePaymentInfo?.abA_PAYWAY_LINK && (
                <DialogActions>
                    <Button
                        target="_blank" href={storePaymentInfo?.abA_PAYWAY_LINK}
                        sx={{ textTransform: 'none' }} fullWidth variant="contained" >Pay with Link</Button>
                </DialogActions>
            )}

        </Dialog>
    </>;
}