
/* eslint-disable */
import { Button, ButtonGroup } from "@mui/material";



export const ItemCategory = ({ itemCategoryList,
    setSelectedCategoryCode }) => {
    return (<ButtonGroup variant="outlined" aria-label="Basic button group">
        <Button style={{textTransform: 'none',color: "#233364"}} onClick={()=> {setSelectedCategoryCode('')}}>All</Button>
        {itemCategoryList?.map(cate => 
            <Button key={cate.id} style={{textTransform: 'none', color: "#233364"}} onClick={()=> {setSelectedCategoryCode(cate.code)}}>{cate.name}</Button>
            
        )}
    </ButtonGroup>);
}   