import { Avatar, Box, Divider, Grid, Typography, Paper,Stack } from "@mui/material";
import { dummyImageBase64 } from "./item";



export const OrderDetail = ({ cartItemList, itemList }) => {
    return (<>
        <Box>
            <Paper elevation={1}>
                <Box p={2}>
                    <Grid container rowSpacing={2} alignItems="center">
                        {cartItemList?.map((cartItem, index) =>
                            <>
                                <Grid item xs={2}>
                                    <Avatar alt={cartItem.itemName} src={`data:image/jpeg;base64,${itemList.find(r => r.code === cartItem.itemCode)?.imageBase64 ?? dummyImageBase64}`} />
                                </Grid>
                                <Grid item xs={6}>

                                    <Typography sx={{ textAlign: 'left', color: "#233364" }} variant="subtitle2" gutterBottom>
                                        <Box sx={{ pr: 4 }}>
                                            {cartItem.itemName}
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    x {cartItem.qty}
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={{ textAlign: 'right' }}>
                                        {(cartItem.qty * cartItem.salePrice).toFixed(2)}
                                    </Box>
                                </Grid>
                                {index < cartItem?.length && (
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </Box>
            </Paper>
        </Box>
        <Box sx={{ mt: 4 }}>
            <Box my={1}>
                <Divider />
            </Box>
            <Box mt={1}>
                <Stack direction="row" spacing={1} justifyContent='space-between'>
                    <Typography variant="body2">Sub total:</Typography>
                    <Typography variant="body2">
                        $ {cartItemList.reduce(function (prev, current) {
                            return prev + (current.qty * current.salePrice)
                        }, 0).toFixed(2)}
                    </Typography>

                </Stack>
            </Box>
            <Box mt={1}>
                <Stack direction="row" spacing={1} justifyContent='space-between'>
                    <Typography variant="body2">Discount :</Typography>
                    <Typography variant="body2">{0.00}</Typography>

                </Stack>
            </Box>
            <Box mt={1}>
                <Stack direction="row" spacing={1} justifyContent='space-between'>
                    <Typography variant="body2">VAT :</Typography>
                    <Typography variant="body2">{0.00}</Typography>

                </Stack>
            </Box>
            <Box mt={1}>
                <Stack direction="row" spacing={1} justifyContent='space-between'>
                    <Typography variant="body2" sx={{ fontWeight: '600' }}>Grand Total :</Typography>
                    <Typography variant="body2">
                        $ {cartItemList.reduce(function (prev, current) {
                            return prev + (current.qty * current.salePrice)
                        }, 0).toFixed(2)}
                    </Typography>
                </Stack>
            </Box>
            {/* <Box mt={2}>
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={3}><Box sx={{ textAlign: 'right' }}> Sub total:</Box></Grid>
                    <Grid item xs={3}>
                        <Box sx={{ textAlign: 'right' }}> {
                            cartItemList.reduce(function (prev, current) {
                                return prev + (current.qty * current.salePrice)
                            }, 0).toFixed(2)
                        }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={3}><Box sx={{ textAlign: 'right' }}> Discount:</Box></Grid>
                    <Grid item xs={3}>
                        <Box sx={{ textAlign: 'right' }}> {0.00.toFixed(2)}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={3}><Box sx={{ textAlign: 'right' }}> VAT:</Box></Grid>
                    <Grid item xs={3}>
                        <Box sx={{ textAlign: 'right' }}> {0.00.toFixed(2)}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={3}><Box sx={{ textAlign: 'right' }}> Grand Total:</Box></Grid>
                    <Grid item xs={3}>
                        <Box sx={{ textAlign: 'right' }}> {
                            cartItemList.reduce(function (prev, current) {
                                return prev + (current.qty * current.salePrice)
                            }, 0).toFixed(2)
                        }
                        </Box>
                    </Grid>
                </Grid>
            </Box> */}
        </Box></>);
}