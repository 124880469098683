
/* eslint-disable */
import { Add, AddCircle, Edit } from "@mui/icons-material"
import { Box, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const Item = ({ code, name, price, imageBase64,
    qtyInCart, addQuantity, minusQuantity }) => {


    return <Paper>
        <Box sx={{ p: 1, textAlign: 'center' }}>
            <Box>
                <img
                height = {100}
                  style={{ maxWidth: "100%", }}
                //  width={150} 
                src={`data:image/jpeg;base64,${imageBase64 ?? dummyImageBase64}`} alt={name} />
            </Box>
            <Box>
                <Typography sx={{ textAlign: 'left', color: "#233364" }} noWrap variant="subtitle2" gutterBottom>
                    {name}
                </Typography>
                <Typography sx={{ textAlign: 'left', color: "#233364", fontStyle : 'italic' }} noWrap variant="subtitle2" gutterBottom>
                    {price}
                </Typography>
                {/* <Typography sx={{
                    textAlign: 'left',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical", color: "#233364"
                }} variant="body2" gutterBottom>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                </Typography> */}
            </Box>
            {(qtyInCart <= 0 || qtyInCart == null) && (
                <Box sx={{ width: '100%', textAlign: 'right' }}>
                    <IconButton onClick={() => addQuantity(code)} sx={{ color: "#233364" , py:0}}>
                        <AddCircle fontSize="large" />
                    </IconButton>
                </Box>
            )}
            {qtyInCart > 0 && (
                <Box sx={{ textAlign: 'right', justifyContent: 'center' }}>
                    <Stack
                        direction="row"

                    // spacing={2}
                    >
                        <IconButton onClick={() => minusQuantity(code)} sx={{ color: "red", py:0 }}>
                            <RemoveCircleOutlineIcon fontSize="large" />
                        </IconButton>
                        <Box height={20}
                            width={20}
                            sx={{
                                pt: 0.8,
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: "600",
                                mx: 2, border: '2px solid 233364'
                            }}>
                            {qtyInCart}
                        </Box>
                        <IconButton onClick={() => addQuantity(code)} sx={{ color: "#233364" , py:0 }}>
                            <AddCircle fontSize="large" />
                        </IconButton>
                    </Stack>
                </Box>
            )}
        </Box>
    </Paper>
}



export const dummyImageBase64 = "iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAAAAACIM/FCAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAd0SU1FB+gEAxQxAXuDBukAAAPNSURBVHja7dprb6JAGIbh/f9/rM4MaEV72Fq7VrcHm60HBl78uDNoVdQaRJI+bZ77Q5MmS8K1LwywO7+SH9Kvrz4BQggBjxC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELTqgdhzjj3n4LohIu58qpVkggOR8e9OVLHu3VuGApG+0qZqWuuHOmZyPsTKoBGGQVi1IGiMapjJ+ZB06s4lMKpixh3cmqdfD7HZk3KOqFet+0gHoX6Rs5euGiAD5S6s2UKyCsli5g7WwwwEYlrziutvErdMqEZAkNVVbtc/ypXOPQRmIpvbNUv9My4tfV5gkI+JiB3etC+vHqalb15IiJV/7YZ/NKpm6WUIEWLT91D7Z0oQGvVS8mmNCEnSa5U7nEQ3Dz7j9t90ASFW3nSwcgShejxwbjbd0yFC8uXrg6Jv9q8tK/P57r0DCenpNSTQ3b2/fCtx1I53JJCQ/jbkahfiHB2lolhicEgif9XaEarezqu5n4cKQ9UpzgQP4u7kWXMNCdS4eA3l8wi9MCpI8CDuOZI9rpffxm3xLcWmfh7LWbmZbN7GECHurO4bxn/0GdWNC5CNYylJwSFJOmpppVXYt8VbXTaO1dUFDrGZfRk+Ps2y4pu82C2Hf8hE9uM+AYW4E8u/+4qn5eaxtTDnkvZ8dXXBQvIPxeKf2JlHUYIL2SuN9xxbEnBImqwHdNCRS2ZeAg2xMoleV6cmhx2rmWBDrExbOhxnyeZ5/olk6t5rcCE2m16a0Bgv+XQeeY1+ZnEn4hxuHu7bKhhnxx2BfkCGiJ9H/t1u3mznmAMasryulveAae48B78RxMra4TPHGO5ux4W49cocHcI3gbjrSpd2AENOmgcwRE6aBzAk6+Uf5d8eYqWnSyOgIW4ihKBBTrnXgSF3F34fQ+nMBebbr5VB1D2paCiIEP9LeloJ9hfiScFBbGIrbBlI0DYMBOFkIZVavPt/Q4WYiDy7B7ppXVesZdw6/Hr+jq0atjm5i8NJTll4C4uwO/YyBtjmlOT/U1V525nfeaaea9hCVwPEyihQVQeitAqeMLYCumTy56Zqt4NJLdtM64BYK1m1NcuX7yfCgCTn7WCuJ24pR4sQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEGLELQIQYsQtAhBixC0CEHrx0D+A7n7tE6WaDJVAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDI0LTA0LTAzVDIwOjQ4OjUyKzAwOjAwDYKkdAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyNC0wNC0wM1QyMDo0ODo1MiswMDowMHzfHMgAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjQtMDQtMDNUMjA6NDk6MDErMDA6MDC9AELQAAAAAElFTkSuQmCC"