/* eslint-disable */
import logo from './logo.svg';
import './App.css';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Avatar, Box, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import { Item, dummyImageBase64 } from './components/item';
import { ItemCategory } from './components/itemCategory';
import { BackButton, MainButton, WebAppProvider, useShowPopup, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { PlaceOrder } from './components/placeOrder';
import { OrderDetail } from './components/orderDetail';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { apiUrl } from './config';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';

function App(scrollCheck = true, keyboardCheck = true) {

  const [tabIndex, setTabIndex] = useState(0);
  const [currency, setCurrency] = useState('USD');
  // const storeCode = 'B568D339-4320-483B-AC06-02C0C50F6A0E';
  const [storeName, setStoreName] = useState('');
  const [storeImageBase64, setStoreImageBase64] = useState('');

  const [itemList, setItemList] = useState([]);
  const [itemCategoryList, setItemCategoryList] = useState([]);
  const [storePaymentInfo, setStorePaymentInfo] = useState({});
  const [cartItemList, setCartItemList] = useState([]);
  const showPopup = useShowPopup();
  const [selectedCategoryCode, setSelectedCategoryCode] = useState('');
  const WebApp = useWebApp();

  const [pgSize, setPgSize] = useState(6);
  const [pgNo, setPgNo] = useState(1);



  const queryParameters = new URLSearchParams(window.location.search)
  const storeCode = queryParameters.get("store")

  const [impactOccurred, notificationOccurred, selectionChanged] =
  useHapticFeedback();

  React.useEffect(() => {

    WebApp.ready();
    WebApp.enableClosingConfirmation();



  });

  React.useEffect(() => {
    GetItem(pgSize, pgNo);
    GetStoreInfo();
    // await GetBankPaymentInfo();
  }, []);

  React.useEffect(async () => {
    await GetBankPaymentInfo();
  }, []);


  // const onClick = useCallback(() => {
  // if (tabIndex === 0) {
  //   alert('Order');
  //   tele.MainButton.setText("Review & Payment");
  //   setTabIndex(1);
  // }
  // if (tabIndex === 1) {
  //   alert('Review & Payment');

  //   setTabIndex(2);
  //   tele.MainButton.setText("Place Order");
  // }
  // if (tabIndex === 2) {
  //   alert('Place Order');
  //   tele.close();

  // }
  // }, []);

  // React.useEffect(() => {

  //   // tele.MainButton?.onClick(onClick);

  // }, [tabIndex]);



  // React.useEffect(() => {
  //   // tele.MainButton.setText("Order - " + (currency === "USD" ? "$ " : currency === "RIEL" ? "៛ " : "") +
  //   //   cartItemList.reduce(function (prev, current) {
  //   //     return prev + (current.qty * current.salePrice)
  //   //   }, 0).toFixed(2));

  //   // if (cartItemList.length > 0)
  //   //   tele.MainButton.enable();
  //   // else
  //   //   tele.MainButton.disable();


  //   // tele.MainButton.show();


  // }, [cartItemList]);

  const GetBankPaymentInfo = async () => {
    if (storeCode)
      await axios.get(`${apiUrl}Public/store/bankPaymentInfo?storeCode=${storeCode}`).then(res => {
        if (res.data.isSuccess === true) {
          setStorePaymentInfo(res.data.returnedResult);

        }
      })
  }




  const GetItem = async (_pgSize, _pgNo) => {
    await axios.get(`${apiUrl}Public/store/item?storeCode=${storeCode}&pgSize=${_pgSize}&pgNo=${_pgNo}`).then(async res => {
      if (res.data.isSuccess === true) {
        // setItemList(res.data.returnedResult?.item);

        res.data.returnedResult?.item?.map(i => {
          setItemList(itemList => [...itemList, i]);
        })

        res.data.returnedResult?.itemCategory?.map(c =>{
          if(!itemCategoryList.includes(caches)) {
            setItemCategoryList(itemCategoryList => [... itemCategoryList, c]);
          }
        })


        // var allCate = itemCategoryList?.push(res.data.returnedResult?.itemCategory);
 
        // setItemCategoryList(allCate);


        setPgSize(_pgSize);
        setPgNo(_pgNo);
        if (_pgNo < res.data.returnedResult.pageCount)
          await GetItem(_pgSize, _pgNo + 1);
      }
    })
  }

  const GetStoreInfo = async () => {
    if (storeCode)
      await axios.get(`${apiUrl}Public/store/info?storeCode=${storeCode}`).then(res => {
        if (res.data.isSuccess === true) {
          setStoreName(res.data.returnedResult?.storeName);
          setStoreImageBase64(res.data.returnedResult?.storeLogoBase64);
          WebApp.expand();
        }
      })
  }

  const addQuantity = (code) => {
    // console.log(code);
    impactOccurred('soft');
    var isExistInCart = cartItemList.find(r => r.itemCode === code);
    if (!isExistInCart) {
      var item = itemList?.find(r => r.code === code);

      setCartItemList(cartItemList => [...cartItemList, {
        itemCode: code,
        itemName: item.name,
        qty: 1,
        salePrice: item.salePrice
      }]);
    }
    else {  /// add to existing cart // replace object in 
      var indexInCart = cartItemList.indexOf(isExistInCart);
      isExistInCart.qty = isExistInCart.qty + 1;
      const newItems = [...cartItemList];
      newItems[indexInCart] = isExistInCart;
      console.log(newItems);
      setCartItemList(newItems);
    }
    setCurrency('USD');
  }

  const minusQuantity = (code) => {
    impactOccurred('soft');

    var isExistInCart = cartItemList.find(r => r.itemCode === code);
    if (isExistInCart) {
      var indexInCart = cartItemList.indexOf(isExistInCart);
      isExistInCart.qty = isExistInCart.qty - 1;

      if (isExistInCart.qty <= 0) {
        setCartItemList(cartItemList.filter(r => r.itemCode !== code));
      }
      else {
        const newItems = [...cartItemList];
        newItems[indexInCart] = isExistInCart;
        setCartItemList(newItems);
      }
    }
  }


  const CartClick = () => {
    notificationOccurred('success'); //Vibrate
    if (cartItemList.length <= 0) return;
    setTabIndex(1);
  }
  const ReviewPaymentClick = () => {
    notificationOccurred('success'); //Vibrate
    setTabIndex(2);
  }

  // const PlaceOrderClick = () => 
  //   WebApp.close();
  // }

  useEffect(() => {
    const handleKeydown = (e) => {
      if (
        keyboardCheck &&
        e.ctrlKey &&
        (e.keyCode == "61" ||
          e.keyCode == "107" ||
          e.keyCode == "173" ||
          e.keyCode == "109" ||
          e.keyCode == "187" ||
          e.keyCode == "189")
      ) {
        e.preventDefault();
      }
    };

    const handleWheel = (e) => {
      if (scrollCheck && e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("wheel", handleWheel);
    };
  }, [scrollCheck, keyboardCheck]);

  return itemList?.length > 0 && (
    <Box >
      <WebAppProvider
        options={{
          smoothButtonsTransition: true,
        }}
      >
        {tabIndex === 0 && (
          <>
            {window.location.href.includes('localhost') && (
              <Button onClick={CartClick} >
                {cartItemList.length <= 0 ? "Cart" :
                  "Cart: " + (currency === 'USD' ? "$ " : currency === 'RIEL' ? "៛ " : "") +
                  cartItemList.reduce(function (prev, current) {
                    return prev + (current.qty * current.salePrice)
                  }, 0).toFixed(2)}
              </Button>
            )}


            <BackButton onClick={() => WebApp.close()} />
            <MainButton text={cartItemList.length <= 0 ? "Cart" :
              "Cart: " + (currency === "USD" ? "$ " : currency === "RIEL" ? "៛ " : "") +
              cartItemList.reduce(function (prev, current) {
                return prev + (current.qty * current.salePrice)
              }, 0).toFixed(2)}
              onClick={CartClick} />

            <Box sx={{ px: 2, mt: 2 }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <img width={70} src={`data:image/jpeg;base64,${storeImageBase64 ?? dummyImageBase64}`} alt={name} />
                <Box component='span'>
                  <Typography variant="h6" gutterBottom>
                    {storeName}
                  </Typography>
                </Box>


              </Stack>

            </Box>

            <Box sx={{ px: 2, mt: 1, overflowX: 'scroll' }}>
              <ItemCategory
                setSelectedCategoryCode={cateCode => { setSelectedCategoryCode(cateCode) }}
                itemCategoryList={[...new Map(itemCategoryList.map(item =>
                  [item['code'], item])).values()]} />
                {/* itemCategoryList={itemCategoryList} /> */}

            </Box>

            <Box sx={{ px: 2, mt: 1 }}>
              <Grid justifyContent="flex-start" container columnSpacing={2} rowSpacing={1.5}>
                {itemList?.filter(r => selectedCategoryCode ? r.itemCategoryCode === selectedCategoryCode : true).map(item =>
                  <Grid key={item.id} item xs={6} sm={4} md={3} lg={1}>
                    <Item
                      code={item.code}
                      name={item.name}
                      price={(item.currency === "USD" ? "$" :
                        item.currency === "RIEL" ? "៛" : "") +
                        item.salePrice?.toFixed(2)}
                      imageBase64={item.imageBase64}
                      addQuantity={code => addQuantity(code)}
                      minusQuantity={code => minusQuantity(code)}
                      qtyInCart={cartItemList.find(r => r.itemCode === item.code)?.qty}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

          </>
        )}



        {
          tabIndex === 1 && (
            <Box sx={{ p: 2 }}>
              {window.location.href.includes('localhost') && (

                <Button onClick={ReviewPaymentClick}>Review & Payment</Button>
              )}

              <BackButton onClick={() => setTabIndex(0)} />
              <MainButton text="Review & Payment" onClick={ReviewPaymentClick} />
              <OrderDetail cartItemList={cartItemList} itemList={itemList} />

            </Box>
          )
        }


        {
          tabIndex === 2 && (
            <Box sx={{ p: 2 }}>
              {/* {window.location.href.includes('localhost') && (<Button onClick={PlaceOrderClick}>Place Order</Button>)} */}
              <BackButton onClick={() => setTabIndex(1)} />
              {/* <MainButton text="Place Order" onClick={PlaceOrderClick} /> */}
              <PlaceOrder storeCode={storeCode} WebApp={WebApp} storePaymentInfo={storePaymentInfo} cartItemList={cartItemList} />


            </Box>
          )
        }

      </WebAppProvider>

    </Box >
  );



}

export default App;
